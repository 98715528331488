<template>
  <v-container style="padding-top: 0; padding-bottom: 0">
    <v-row>
      <v-col
        cols="12"
        style="padding-top: 8px; padding-bottom: 0; padding-left: 0"
      >
        <v-banner class="bannercomprarxs">
          <h2
            style="
              text-align: center;
              color: #e6deb5;
              font-family: 'Poppins', Sans-serif;
              font-size: 13px !important;
              font-weight: 600;
              line-height: 1.2em;
              letter-spacing: -0.7px;
            "
          >
            {{ comprarahora.titulo }}
            <v-btn  @click="btnConprarAhora_click"
              style="
                color: #fb950c;
                background-color: transparent;
                padding: 0 1px;
              "
            >
              {{ comprarahora.boton }}
            </v-btn>
          </h2>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.bannercomprarxs .back-banner {
	background-color: transparent;
	background-image: radial-gradient(at center center, #6a289f 42%, #4b0b7f 68%);
}
</style>
<script>
export default {
  data() {
    return {
      comprarahora: {
        titulo:
          "Accede a NIUFLEEX Membresía Premium por solo $50USD mensuales y logra tu rango.",
        boton: "¡Comprar Ahora!",
        link: "https://backoffice.teomacorp.com/login?ReturnUrl=%2f",
        target: "_blank",
        interna: 0,
      },
    };
  },
  methods: {
    btnConprarAhora_click() {
      let routeData = this.$router.resolve({
        path: this.comprarahora.link,
      });
      if (this.comprarahora.interna == 1) {
        window.open(routeData.href, this.comprarahora.target);
      } else {
          window.open(this.comprarahora.link, this.comprarahora.target);
      }
    },
  },  
};
</script>