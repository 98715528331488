<template>
  <v-container fluid style="padding-top: 0; padding-bottom: 0; 
    padding-bottom: 10px;
    margin-top: 0;
    height: 64px;
    margin-bottom: 0;">
    <v-container style="padding-left: 0; padding-right: 0;">
    <v-row>
      <v-col
        cols="12"
        style="padding-top: 3px; padding-bottom: 0; padding-left: 0; background-color: rgb(39, 36, 41);"
      >
        <v-banner single-line>
          <h2
            style="
              color: #e6deb5;
              font-family: 'Poppins', Sans-serif;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.2em;
              letter-spacing: -0.7px;
            "
          >
            {{ comprarahora.titulo }}
          </h2>
          <template v-slot:actions>
            <v-btn class="btnComprar" @click="btnConprarAhora_click">
              {{ comprarahora.boton }}&nbsp;&nbsp;
              <span>
                <i aria-hidden="true" class="fas fa-mouse-pointer"></i>
              </span>
            </v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
    </v-container>
  </v-container>
</template>
<style>
.btnComprar {
  font-family: "Raleway", Sans-serif !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  letter-spacing: 0px !important;
  fill: #e6deb5 !important;
  color: #e6deb5 !important;
  background-color: #32323200 !important;
  border-style: solid !important;
  border-width: 2px 2px 2px 2px !important;
  border-color: #e6deb5 !important;
  border-radius: 5px 5px 5px 5px !important;
  padding: 12px 20px 9px 20px !important;
  height: 44px !important;
}
.btnComprar:hover {
  color: #ffffff !important;
  background-color: #8208b100 !important;
}
</style>
<script>
export default {
  data() {
    return {
      comprarahora: {
        titulo:
          "Accede a NIUFLEEX Membresía Premium por solo $50USD mensuales y logra tu rango.",
        boton: "¡Comprar Ahora!",
        link: "https://backoffice.teomacorp.com/login?ReturnUrl=%2f",
        target: "_blank",
        interna: 0,
      },
    };
  },
  methods: {
    btnConprarAhora_click() {
      let routeData = this.$router.resolve({
        path: this.comprarahora.link,
      });
      if (this.comprarahora.interna == 1) {
        window.open(routeData.href, this.comprarahora.target);
      } else {
        window.open(this.comprarahora.link, this.comprarahora.target);
      }
    },
  },
};
</script>