<template>
	<v-container
		style="padding-top: 0; padding-bottom: 0; height: 350px; padding: 44px" light
	>
		<v-row v-if="encuesta">
			<v-col cols="12" lg="3" xl="3"></v-col>
			<v-col cols="12" lg="6" xl="6">
				<v-card light
					elevation="0"
					style="
						border-style: solid;
						border-width: 1px 1px 1px 1px;
						border-color: #c1c1c1;
						margin: 0px 0px 0px 0px;
						--e-column-margin-right: 0px;
						--e-column-margin-left: 0px;
						padding: 30px 20px 15px 20px;
					"
				>
					<v-card-title style="text-align: center">
						<h2
							style="
								color: #5a5a5a;
								font-family: 'Poppins', Sans-serif;
								font-size: 20px;
								font-weight: 400;
								line-height: 1.2em;
								letter-spacing: -0.7px;
								word-break: break-word;
							"
						>
							¿Navegar en nuesta plataforma te ha resultado fácil y agradable?
						</h2>
					</v-card-title>
					<v-card-subtitle style="text-align: center; padding-top: 15px">
						<v-btn
							text
							class="fives"
							id="btnFiveStar1"
							@click="btnFive_click(1)"
						>
							<i class="fas fa-gem" style="font-size: 22px"></i>
						</v-btn>

						<v-btn
							text
							class="fives"
							id="btnFiveStar2"
							@click="btnFive_click(2)"
						>
							<i class="fas fa-gem" style="font-size: 22px"></i>
						</v-btn>

						<v-btn
							text
							class="fives"
							id="btnFiveStar3"
							@click="btnFive_click(3)"
						>
							<i class="fas fa-gem" style="font-size: 22px"></i>
						</v-btn>

						<v-btn
							text
							class="fives"
							id="btnFiveStar4"
							@click="btnFive_click(4)"
						>
							<i class="fas fa-gem" style="font-size: 22px"></i>
						</v-btn>

						<v-btn
							text
							class="fives"
							id="btnFiveStar5"
							@click="btnFive_click(5)"
						>
							<i class="fas fa-gem" style="font-size: 22px"></i>
						</v-btn>
					</v-card-subtitle>
					<v-card-actions>
						<v-btn style="box-shadow: none;" class="btnEnviarFiveStar" @click="btnEnviarFiveStar_click"
							>enviar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="3" xl="3"></v-col>
		</v-row>
		<v-row v-else>
			<v-col>
				<label>¡Muchas gracias por responder!</label>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.fives {
	padding: 10px !important;
	border: none !important;
	min-width: 0 !important;
	padding: 5px !important;
}
.fives:hover {
	background-color: transparent !important;
	color: #f3c660;
	opacity: 1;
}
.btnEnviarFiveStar {
	font-family: "Raleway", Sans-serif !important;
	font-size: 17px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #8208b1 !important;
	height: 40px !important;
	width: 100% !important;
	color: #8208b1 !important;
	background-color: #ffffff !important;
	border-style: solid !important;
	border-width: 3px 3px 3px 3px !important;
	border-color: #8208b1 !important;
	border-radius: 3px 3px 3px 3px !important;
	padding: 10px 15px 7px 15px !important;
}
.btnEnviarFiveStar:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
</style>
<script>
export default {
	data() {
		return {
			encuesta: true,
			indice: 0,
		};
	},
	methods: {
		async btnEnviarFiveStar_click() {
			if (this.indice > 0) {
				let result = (
					await this.$http.post("/encuestas/encuesta", {
						encuesta_pregunta_id: 1,
						respuesta: this.indice,
					})
				).data;
				if (result) {
					this.encuesta = false;
				}
			}
			// let routeData = this.$router.resolve({
			// 	path: this.modulosReproducidos.link,
			// });
			// if (this.modulosReproducidos.interna == 1) {
			// 	window.open(routeData.href, this.modulosReproducidos.target);
			// } else {
			// 	window.open(
			// 		this.modulosReproducidos.link,
			// 		this.modulosReproducidos.target
			// 	);
			// }
		},
		async btnFive_click(indice) {
			this.indice = indice;
			switch (indice) {
				case 1:
					document.getElementById("btnFiveStar1").style.color = "#f3c660";
					document.getElementById("btnFiveStar2").style.color = "black";
					document.getElementById("btnFiveStar3").style.color = "black";
					document.getElementById("btnFiveStar4").style.color = "black";
					document.getElementById("btnFiveStar5").style.color = "black";
					break;
				case 2:
					document.getElementById("btnFiveStar1").style.color = "#f3c660";
					document.getElementById("btnFiveStar2").style.color = "#f3c660";
					document.getElementById("btnFiveStar3").style.color = "black";
					document.getElementById("btnFiveStar4").style.color = "black";
					document.getElementById("btnFiveStar5").style.color = "black";
					break;
				case 3:
					document.getElementById("btnFiveStar1").style.color = "#f3c660";
					document.getElementById("btnFiveStar2").style.color = "#f3c660";
					document.getElementById("btnFiveStar3").style.color = "#f3c660";
					document.getElementById("btnFiveStar4").style.color = "black";
					document.getElementById("btnFiveStar5").style.color = "black";
					break;
				case 4:
					document.getElementById("btnFiveStar1").style.color = "#f3c660";
					document.getElementById("btnFiveStar2").style.color = "#f3c660";
					document.getElementById("btnFiveStar3").style.color = "#f3c660";
					document.getElementById("btnFiveStar4").style.color = "#f3c660";
					document.getElementById("btnFiveStar5").style.color = "black";
					break;
				case 5:
					document.getElementById("btnFiveStar1").style.color = "#f3c660";
					document.getElementById("btnFiveStar2").style.color = "#f3c660";
					document.getElementById("btnFiveStar3").style.color = "#f3c660";
					document.getElementById("btnFiveStar4").style.color = "#f3c660";
					document.getElementById("btnFiveStar5").style.color = "#f3c660";
					break;
				default:
					break;
			}
		},
	},
};
</script>